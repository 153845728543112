@import url(https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css);
button.button {
  outline: none;
  border: none;
  border-radius: 3px;
  
  padding: 12px 10px 12px 10px;

  cursor: pointer;

  opacity: 0.95;

  transition: opacity 200ms ease;

  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

button.button:hover {
  opacity: 0.8;
}

button.button:active {
  opacity: 1;
}
/* :root {
  --welcome-container-padding: var(--app-padding);
} */

@media only screen and (max-width: 900px) {
  h1.welcome-heading {
    font-size: 36px;
  }
  p.welcome-explanation {
    font-size: 18px;
  }
}

@media only screen and (min-width: 901px) {
  h1.welcome-heading {
    font-size: 4vw;
  }
  p.welcome-explanation {
    font-size: 2vw;
  }
}

div.welcome-container {
  width: calc(100% - (2 * var(--app-padding)));
  padding: var(--app-padding);
}

h1.welcome-heading, p.welcome-explanation {
  text-align: center;
}

h1.welcome-heading {
  margin: 10px 0px 20px 0px;
}

button.button.button-welcome {
  background: var(--theme-color);
  color: #fff;
  
  width: 100%;
  min-height: 60px;
  height: 10vh;

  font-size: 25px;
  font-weight: 700;

  margin-top: 50px;

  padding: 15px;
}
div.setup-container {
  width: calc(100% - (2 * var(--app-padding)));
  padding: var(--app-padding);
}

div.setup-container-grid {
  display: grid;
  grid-template-rows: 60px auto 60px;

  width: 100%;
  height: 100%;
  max-height: 100vh;

  margin: 0px;
  padding: 0px;
}

h1.setup-heading {
  margin-top: 5px;
}

div.setup-books-container {
  overflow: scroll;
  padding-bottom: var(--app-padding);
}

/* SetupBookBox component. Should I move this into a different folder?? */
div.setup-book-box {
  width: calc(100% - (2 * var(--app-padding)));
  height: 200px;

  background: #fff;
  border: none;
  border-radius: 10px;

  padding: var(--app-padding);
  margin-bottom: var(--app-padding);

  position: relative;
}
/* remove book button */
div.setup-book-box .setup-remove-book {
  position: absolute;
  top: var(--app-padding);
  right: var(--app-padding);

  width: 35px;
  height: 35px;
  font-size: 25px;

  border: none;
  outline: none;
  border-radius: 50%;

  background-color: #b71c1c;
  color: #fff;

  cursor: pointer;
}
div.setup-book-box .setup-remove-book div.inner {
  position: relative;
  width: 100%;
  height: 100%;
}
div.setup-book-box .setup-remove-book div.inner svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
div.setup-book-box div.setup-fields-grid {
  width: calc(100% - 65px);
  display: grid;
  grid-template-rows: auto;
}
div.setup-book-box div.setup-fields-grid input {
  padding: 4px;
}
/* date picker fix */
div.setup-book-box div.setup-fields-grid div.react-datepicker-wrapper input {
  width: calc(100% - 10px);
  height: 100%;
}

/* add book button */
button.button.button-setup-add-book {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 75px;
  font-size: 30px;
  font-weight: 500;

  background: #ddd;
}
button.button.button-setup-add-book svg {
  padding-right: 5px;
}

/* next button */
button.button.button-setup-next {
  width: 100%;
  height: 100%;

  background: var(--theme-color);
  color: #fff;
  font-weight: 700;

  font-size: 22px;
}
div.dashboard-container {
  width: calc(100% - (2 * var(--app-padding)));
  padding: var(--app-padding);
}

h1.dashboard-heading {
  margin-top: 5px;
}
:root {
  --app-padding: 15px;
  --theme-color: #305475;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
  background: #f4f4f4;
}

